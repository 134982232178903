// src/app/(main)/(home)/error.js

"use client";

import FormButton from "@/components/ui/auth/FormButton";
import { Flex, Grid, Heading, Text } from "@chakra-ui/react";
import Link from "next/link";
import LogoDark from "@/assets/svg/logo-dark.svg";
import { captureException } from "@sentry/react"; // Use react for client side error functions
import { sentryFeedbackHandler } from "@/utils/sentryFeedback";

export default function ErrorPage({ error }) {
  captureException(error);

  return (
    <Grid h={"85vh"} w={"calc(100vw - 12px)"} py={"24px"} placeItems={"center"}>
      <Flex flexDir={"column"} rowGap={"24px"} alignItems={"center"}>
        <Flex alignItems={"center"} justifyContent={"center"} w={"100%"}>
          <Link href={"/"} alt={"Home"}>
            <LogoDark width={216} height={46} />
          </Link>
        </Flex>
        <Heading
          fontSize={"96px"}
          fontWeight={"700"}
          letterSpacing={"0.68px"}
          lineHeight={"normal"}
          fontFamily={"Roslindale"}
          color={"mainBlue"}
          textAlign={"center"}
        >
          Error
        </Heading>
        <Text
          textAlign={"center"}
          color={"grey"}
          fontSize={"24px"}
          fontWeight={"500"}
          lineHeight={"1.35"}
        >
          {error?.message || "Something went wrong!"}
        </Text>
        <FormButton
          title={"Report a problem"}
          button={{
            onClick: () => {
              sentryFeedbackHandler();
            },
            maxW: "320px",
          }}
        />
      </Flex>
    </Grid>
  );
}
